@import 'resetAntd.css';
@import 'responsive.scss';
@import 'variables.css';
@import 'animation.css';

#root {
  display: flex;
  min-height: 100%;
}

.h1Big {
  font-size: 32px;
  line-height: 40px;
  color: var(--color-gray_2);
  margin-bottom: 12px;
  font-weight: 600;

  @include device(extraMediumSmall) {
    font-size: 20px;
    line-height: 32px;
  }
}

.customMarginBottom {
  margin-bottom: 8px !important;
}

.customMarginBottomAndTop {
  margin: 16px 0;
}

.alignCenter {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.justifyStart {
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
}

.heightInherit {
  height: inherit;
}

hr {
  height: 1px !important;
  background-color: var(--border-color);
  opacity: 0.5;
  margin-top: 16px;
  margin-bottom: 16px;
}

.fancyRowContent {
  display: flex;
  text-align: center;
  height: inherit;
}

.fancyRowContent .imageColumn {
  flex: none;
  min-width: 400px;
  @include device(smallOnly) {
    min-width: 150px;
  }
}

.fancyRowContent .imageColumn img {
  width: 97px;
  max-height: 54px;
  border-radius: 4px;
  float: left;
  @include device(smallOnly) {
    width: 65px;
    height: 54px;
  }
}

.fancyRowContent .imageColumn p {
  color: var(--color-gray_2);
  font-size: 14px;
  font-weight: 500;
  @include device(smallOnly) {
    margin-left: 40px;
  }
}

.fancyRowContent div {
  position: relative;
  flex: 1;
}

.fancyRowContent p {
  color: var(--color-gray_2);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @include device(smallOnly) {
    width: 100%;
    margin-left: 10px;
  }
  @include device(mediumOnly) {
    width: 100%;
    margin-left: 10px;
  }

  img {
    padding-right: 11px;
  }

  span {
    color: var(--color-white);
  }
}

.fancyRowContent .actionsColumn img {
  width: 24px;
  height: 24px;
  margin-right: 19px;
  margin-top: 15px;
  cursor: pointer;
}

.fancyRowContent .actionsColumn {
  display: block;
  float: right;
  text-align: right;
}

.addNewBuildingSchedule {
  height: inherit;
  display: grid;
  cursor: pointer;
  margin-top: -7px;
}

.headerButtons {
  // height: 40px;
  display: flex;
  flex-direction: 'row';
  gap: 32px;
  float: right;
  margin-top: 13px;

  @include device(smallOnly) {
    max-width: 300px;
  }

  @include device(mediumOnly) {
    max-width: 300px;
  }
}

.saveButton {
  width: 168px;
  height: 40px;
  float: right;
  margin-left: 22px;
  @include device(smallOnly) {
    width: 100px;
  }
}

.formSaveButton {
  width: 102px;
  height: 40px;
  float: right;
}

.formCancelButton {
  width: 102px;
  height: 40px;
  float: right;
  margin-right: 10px;
}

#removeButton {
  width: 168px;
  height: 40px;
  @include device(smallOnly) {
    width: 100px;
  }
}

.reset-style-button {
  cursor: pointer;
}

.cancelButton {
  width: 168px;
  height: 40px;
  float: right;
  @include device(smallOnly) {
    width: 100px;
  }
}

.bufferTime {
  display: flex;
  width: 117px;
  float: right;
}

.disabledField {
  background-color: var(--color-gray_1) !important;
}

.right {
  float: right !important;
}

.alignRight {
  text-align: right;
}

.resizeField div {
  display: contents;
  width: calc(100% - 20px);
  float: right;
  margin-left: 60px;
  margin-top: 8px;
  margin-bottom: 8px;

  .ant-select-selector {
    margin: 0px;
  }
}

.scheduleRow {
  .ant-select-selector {
    padding-left: 5px !important;
  }

  margin-bottom: 16px;

  .resizeFieldSchedule div {
    height: 40px !important;
    width: calc(100% - 10px);
    float: right;
    padding-left: 5px;

    .ant-select-disabled {
      padding-left: 0px !important;
    }
  }

  .resizeFieldSchedule:last-child {
    margin-right: -10px !important;
    display: inline !important;
  }
}

.left {
  float: left !important;
}

.detailsText {
  width: inherit;
  height: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-dark-03);
  margin-top: 8px;
}

.errorText {
  width: inherit;
  height: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-required);
  margin-top: 2px;
}

.fancyInputDate {
  padding: 8px 0px 12px 12px;
  width: 100%;
  height: 40px;
  line-height: 16px;
  background: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.noPaddingBottom {
  padding-bottom: 0px !important;
}

.backTo {
  display: inline-block;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-gray_2);
  cursor: pointer;

  img {
    margin-right: 10px;
    margin-bottom: 3px;
  }
}

.addContainerButton {
  background-color: var(--link-color) !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--color-white) !important;
  padding: 6px 24px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.emptyItems {
  width: 100%;
  margin-top: 25%;

  p {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: var(--color-gray_4);
  }
}

.marginRightSmall {
  margin-right: 16px;
}

.marginBottomSmall {
  margin-bottom: 16px;
}

.marginRight {
  margin-right: 32px;
}

.marginTop {
  margin-top: 32px;
}

.marginTopButton {
  margin-top: 24px;
}

.marginTopForm {
  margin-top: 22px;
}

.rowMarginBottom {
  margin-bottom: 24px;
}

.ant-table-column-sorter {
  color: var(--submit-button) !important;
}

.ant-table-column-sorters {
  float: left;
}

.ant-table-cell img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.customBr {
  padding-top: 2px;
}

.hardwareContainer {
  width: 266px;
  height: 87px;
  background: var(--color-secondary-80);
  border-radius: 8px;
  cursor: initial !important;

  div {
    padding: 16px;

    .ant-checkbox-wrapper {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      float: left;
      margin-top: -3px;
    }

    p {
      display: inline-block;
      color: var(--color-white);
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      padding-left: 8px;
      cursor: pointer;
    }

    img {
      display: inline;
      float: right;
      cursor: pointer;
    }

    span {
      color: var(--color-white);
      display: flex;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.addRowCell {
  width: auto;
}

.formLabelCheckbox {
  display: inline;
  position: absolute;
  margin: 5px 0px 0px 5px;
}

.fullWidth {
  width: 100% !important;
}

.infoIcon {
  padding: 0px 5px;
}

.greenLink {
  color: var(--color-green) !important;
  cursor: pointer;
  margin: 0 !important;

  &:hover {
    color: var(--color-orange);
    opacity: 0.5;
  }
}

.customLink {
  color: var(--color-orange) !important;
  cursor: pointer;
  margin: 0 !important;

  &__active {
    color: var(--color-green) !important;

    &:hover {
      color: var(--color-green) !important;
      opacity: 0.5;
    }
  }

  &:hover {
    opacity: 0.5;
    color: var(--color-orange) !important;
  }
}

.hideElement {
  display: none !important;
}

.partOfSectionItem {
  background: var(--color-dark-03);
  color: var(--color-white);
  border-radius: 16px;
  width: auto;
  display: inline-block;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  margin-right: 16px;
}

.titleBlock {
  display: inline-block;
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-gray_2);
  margin-bottom: 16px;
}

.titleBlockLineHeight {
  line-height: 42px;
}

.customLineHeight {
  line-height: 40px;
}

.imgFancyRow {
  width: 79px;
  height: 54px;
  margin-top: -19px;
}

.imageSiteCard {
  width: 100%;
  height: 261px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.roomDetailsTextCenter {
  width: 100%;
  height: calc(100vh - 500px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-gray_4);
}

.roomsDetailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.imageRoom {
  width: 266px;
  height: 167px;
  margin-bottom: 8px;
  border-radius: 8px;
  @include device(extraMediumSmall) {
    width: 100%;
    height: 220px;
  }
}

.moveTop {
  position: absolute;
  top: 100px;
  float: right;
  right: 0px;
  margin-right: 24px;
  width: inherit;
}

.customSelectBox .ant-select-selector,
.customSelectBox .ant-select-item.ant-select-item-option {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-light-06);

  .ant-select-selection-placeholder {
    padding-top: 4px;
  }
}

.actionsList {
  .ant-select-selection-item {
    padding-right: 0px !important;
    line-height: 15px !important;
  }
}

.modalContainer {
  width: 525px;
}

.cursorPointer {
  cursor: pointer;
}

.requiredField {
  font-size: 14px;
  line-height: 1.5;
}

.requiredField:after {
  content: ' *';
  color: var(--color-required);
}

.formActionsButtons {
  margin-bottom: 57px;
}

.img-crop-modal {
  .img-crop-control {
    width: 85%;
    margin-bottom: 24px !important;
  }

  .ant-modal-content {
    min-width: 600px;
    border-radius: 16px !important;
    @media screen {
      @media (max-width: 779px) {
        min-width: 100%;
        width: calc(100vw - 20px) !important;
      }
    }
  }

  .ant-modal-header {
    padding: 16px 32px 0px 32px !important;
    border-bottom: none !important;
    border-top-right-radius: 16px !important;
    border-top-left-radius: 16px !important;

    .ant-modal-title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px !important;
      line-height: 32px;
      margin: 0;
      color: var(--color-gray_3);
    }
  }

  .ant-modal-body {
    padding: 16px 32px 0 !important;
  }
}

.modalDeleteConfirmation .ant-modal-content {
  h3 {
    margin-bottom: 24px;
  }

  .ant-modal-body {
    padding: 24px !important;

    p {
      margin-bottom: 24px;
    }
  }

  .ant-modal-footer {
    display: none !important;
  }
}

.textTooltip {
  max-width: 145px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  @include device(extraMediumSmall) {
    max-width: 300px;
  }
}

.siteNavigation > div:first-of-type {
  width: auto;
  white-space: nowrap;
  max-width: 1000px;
  @include device(siteNavigationBar) {
    overflow-x: scroll;
    padding-bottom: 12px;
    margin-bottom: -12px;
  }
}

.periodOfTime {
  display: table;
  width: 100%;

  img {
    display: table-cell;
    float: left;
  }

  p {
    padding-left: 11px;
    display: flex;
    margin-bottom: 0px;
    line-height: 24px;
    @include device(smallOnly) {
      margin-top: -10px;
    }
  }
}

.clickable {
  cursor: pointer;
}

.moveRight {
  margin-right: -6px;
}

.filterAndToggle {
  margin-top: -20px;
  float: right;

  div {
    display: contents;
  }

  > .sectionName {
    margin-right: 18px;
    margin-left: 12px;
  }

  > .sectionNameRight {
    margin-left: 12px;
  }
}

.filterButton {
  float: none;
  width: 100%;
}

.filterSection {
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-dark-04);
    margin-bottom: 16px;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    column-gap: 8px;
    margin: 0 !important;
    margin-bottom: 14px !important;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-gray_2);
    }
  }
}

.bufferTimeSelect {
  width: 117px;
  height: 40px;
  float: right;
}

.noMarginBottom {
  margin-bottom: 0px !important;
}

.customLineHeight {
  line-height: 38px;
}

.nameOfImage {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.toggleRowButton {
  margin-left: 20px;

  label {
    input:checked + span {
      background-color: var(--color-orange) !important;
    }
  }
}

.toogleDisabled {
  opacity: 0.5;

  // label{
  //   span{
  //     background-color: var(--color-white) !important;
  //     border: 1px solid var(--color-orange);
  //   }
  //   span::before{
  //      bottom: 3px !important;
  //     }
  // }
}

.toogleNoBorder {
  span {
    border-color: transparent !important;
  }
}

.siteStatus {
  position: relative;

  .changeSiteStatus {
    font-size: 12px;
    color: var(--color-dark-03);
    bottom: 2.5px;
    left: 75px;
    position: absolute;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  button {
    min-width: 135px;
  }

  .page-title {
    margin-bottom: 0px !important;
  }
}

.defaultXMargins {
  margin: 0px 24px;
}

.ant-col {
  .ant-form-item-explain-error {
    margin-left: 10px !important;
    color: var(--color-required);
  }
}

.justifyEnd {
  display: flex;
  justify-content: flex-end !important;
  text-align: right;
  display: flex !important;
  align-items: center !important;
}

.m-0 {
  margin: 0 !important;
}

.font-weight-700 {
  font-weight: 700;
}

.addNewTable {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  padding: 0 !important;
  background-color: initial !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--color-orange) !important;
  height: inherit !important;
}

.addNewCarsBtn {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 100% !important;
    color: var(--color-orange) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    width: 100% !important;
  }
}

.customSelectLabel {
  margin-bottom: 10px;

  label {
    display: block;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-gray_3);
    padding-bottom: 8px;
  }

  .ant-select {
    width: auto;
    min-width: 415px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
  }

  .ant-select-selector {
    border-radius: 6px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.parentHeaderPage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lowercase-input {
  text-transform: lowercase !important;
}

.actionHref {
  display: contents;

  img {
    margin-right: -6px;
  }
}

.actionImg {
  width: 24px;
  height: 24px;
}

.ambientMusic {
  min-width: 563px;
  height: 40px;
  background: #f2f2f7;
  border: 1px solid #d1d1d6;
  border-radius: 6px;

  .ant-select-selector {
    border-radius: 6px !important;
    display: inline !important;
    max-height: 38px;
  }

  .ant-select-arrow {
    display: none;
  }

  .playlistImage {
    padding-right: 8px;
  }
}

.img-crop-container {
  border-radius: 6px;
}

.ant-slider-track {
  background-color: var(--color-primary-100) !important;
}

.ant-slider-handle {
  border: 2px solid var(--color-primary-100) !important;
}

.profileDetailsCell {
  padding-left: 0px !important;
  display: flex;
  top: 30px;
  float: right;
  height: 27px;
  width: 27px;
}

.leasesHeaderDetails {
  display: table;
  width: 100%;

  h1 {
    display: table-cell;
    float: left;
    margin-right: 16px;
  }

  .badgeStatus {
    border-radius: 16px;
    font-weight: 500;
    font-size: 12px;
    padding: 4px 8px;
    margin-left: 0px;
    line-height: 42px;
  }

  .activeNow {
    background-color: var(--color-primary-100) !important;
    color: var(--color-secondary-100);
  }

  .finished {
    color: var(--color-white);
    background: var(--link-color);
  }

  .addNew {
    margin-top: 0px;
  }
}

.leaseDepositRow {
  height: 64px !important;

  div {
    padding-top: 18px;
  }

  .numberOfImages {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding-top: 2px;
  }

  img {
    padding-right: 8px;
  }
}

.flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.depositContainerSlots {
  background-color: var(--color-white);
  padding: 0px 17px;

  .titleBlock {
    margin-bottom: 8px;
  }

  .card-image {
    border-radius: 4px;
  }

  .infoMessage {
    text-align: center;
    box-sizing: border-box;
    height: 56px;
    background: var(--color-gray_1);
    border: 1px solid var(--color-gray_1);
    border-radius: 4px;
    margin-bottom: 18px;
    display: inline-block;
    width: 100%;
    position: relative;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 56px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .procentOption {
    width: 76px;
    height: 25px;
    background: #ffffff;
    border: 1px solid #d1d1d6;
    border-radius: 6px;

    .ant-select-selector {
      height: 20px !important;
      border-radius: 8px !important;

      .ant-select-selection-item {
        line-height: 24px !important;
      }
    }
  }
}

.scheduleCalendar {
  min-width: 525px;
}

.calendarSlots {
  margin-left: 16px !important;
}

.fontWeight-500 {
  font-weight: 500;
}

.whiteLabelSelect {
  &.ant-select-single {
    &.ant-select-open {
      .ant-select-selection-item {
        color: var(--color-white) !important;
      }
    }
  }
}

.realTimeStatus {
  width: 8px;
  height: 8px;
  display: inline;
  float: left;
  margin: 4px 4px 0px 0px;
  border-radius: 50%;
}

.statusUnknown {
  background: var(--color-primary-100);
}

.statusActive {
  background: var(--color-green);
}

.statusInactive {
  background: var(--color-orange2);
}

.fieldLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  color: var(--color-dark-06);
  margin-bottom: 0;
  padding: 0 0 4px 0;
}

#faq-form {
  .ant-picker {
    width: 100%;
  }

  textarea {
    border-radius: 6px;
    min-height: 117px;
  }
}

.removeMarginBottom {
  margin-bottom: 0px !important;
}

.no-arrows-input::-webkit-outer-spin-button,
.no-arrows-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.new-table-row-actions {
  display: flex;

  button {
    &.ant-btn.ant-btn-default.button {
      padding: 0px !important;
      width: 50px !important;
      min-width: auto !important;

      img {
        min-width: 18px !important;
      }
    }
  }
}

.whiteColor {
  color: var(--color-white);
}

.ant-notification-notice-wrapper {
  border-radius: 13px !important;
  overflow: hidden;
  border: 1px solid var(--color-dark-04);
}

.notification {
  box-shadow: 0 4px 4px rgba(82, 79, 78, 0.24) !important;
  padding: 16px 50px 16px 16px !important;

  &InfoIcon {
    width: 24px;
  }

  &Success {
    background-color: var(--color-green) !important;
    color: var(--color-white) !important;

    .ant-notification-notice-message {
      color: var(--color-white) !important;
    }
  }

  &Warning {
    background-color: var(--color-primary-100) !important;
  }

  &Error {
    background-color: var(--color-orange) !important;
    color: var(--color-white) !important;

    .ant-notification-notice-message {
      color: var(--color-white) !important;
    }
  }

  &CloseIcon {
    width: 24px !important;
  }

  ul {
    margin: 0;
  }

  .ant-notification-notice-description {
    color: var(--color-white) !important;
  }
}

.header-info-skeleton {
  width: 70% !important;
  border-radius: 10px !important;
  height: 40px !important;
}

.disabledContainer {
  pointer-events: none !important;
  opacity: 0.6;

  * {
    pointer-events: none !important;
  }

  input {
    pointer-events: none !important;
  }
}

.noMarginBottom {
  margin-bottom: 0px !important;
}

.input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-dark-06);
  padding: 0 0 4px 0;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev {
  @include device(extraMediumSmall) {
    font-size: 14px;
    margin-right: 2px !important;
  }
}

.ant-pagination.ant-pagination-mini .ant-pagination-next {
  @include device(extraMediumSmall) {
    font-size: 14px;
    margin-left: 2px !important;
  }
}

.ant-pagination-item {
  @include device(extraMediumSmall) {
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
    font-size: 14px !important;
  }
}

.ant-table-pagination-right {
  @include device(extraMediumSmall) {
    justify-content: center !important;
  }
}

.drag-handle {
  cursor: move;
}

.drag-handle:active {
  cursor: grabbing;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.search-input-row {
  margin: 0 0 16px 0;

  .hint {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: var(--color-dark-03);
  }
}

td {
  vertical-align: middle !important;
}

.main-label {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-dark-06);
  margin-bottom: 0;
  padding: 0 0 4px 0;
}

.label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-dark-06);
  margin-bottom: 0;
  padding: 0 0 4px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.hidden {
  visibility: hidden !important;
}
