:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-ddd: #dddddd;

  --color-gray: #e5e5ea;
  --color-gray_1: #f2f2f7;
  --color-gray_2: #3d3938;
  --color-gray_3: #272322;
  --color-gray_4: #293854;
  --color-gray_5: #4e4e5a;
  --border-color: #d1d1d6;
  --border-color1: #cbc2c0;
  --border-color2: #e5e7eb;

  --color-orange: #cb7d61;
  --color-orange1: #b19f9b;
  --color-orange2: #f77167;
  --submit-button: #eed382;
  --link-color: #63382c;

  --color-purple-01: #8588e0;

  --button-primary-background: #eed382;
  --button-primary-color: #63382c;
  --button-secondary-color: #63382c;
  --button-tertiary-background: #63382c;

  --color-dark-01: #a9a7a7;
  --color-dark-02: #939190;
  --color-dark-03: #7d7b7a;
  --color-dark-04: #524f4e;
  --color-dark-05: #3d3938;
  --color-dark-06: #272322;

  --color-light-01: #ffffff;
  --color-light-02: #f2f2f7;
  --color-light-03: #e5e5ea;
  --color-light-04: #d1d1d6;
  --color-light-05: #c7c7cc;
  --color-light-06: #aeaeb2;

  --color-grey-02: #dee5ef;

  --color-primary-20: #fff7e2;
  --color-primary-40: #e8ddbd;
  --color-primary-60: #ebdbaa;
  --color-primary-80: #ecd796;

  --color-primary-100: #eed382;

  --color-secondary-20: #cbc2c0;
  --color-secondary-80: #7d5b51;
  --color-secondary-100: #63382c;

  --color-tertiary-color-20: #e0d0ca;
  --color-tertiary-color-40: #dabbb0;
  --color-tertiary-color-80: #d0927c;
  --color-tertiary-color-100: #cb7d61;

  --color-background: #f9f9f9;
  --color-background-select-arrow: #ecd796;
  --color-required: #f67167;
  --color-green: #7eb781;
  --color-blue: #45a3d9;
  --color-blue-01: #45a3d94d;

  --color-system-success: #e0f1e1;
  --color-system-success-border: #7eb781;
  --color-system-info: #fff7e2;
  --color-system-info-border: #f4c86e;
  --color-system-error: #fbebe9;
  --color-system-error-border: #f77167;

  --color-warking-transparent: #ff8e86;
  --color-gray_6: #afafaf;

  --color-disabled: #f5f5f5;
}
