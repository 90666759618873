@import './responsive.scss';

.form-wrapper {
  .controlContainer {
    margin: 16px 0px;
  }

  .formTitle {
    height: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-gray_2);
    margin: 16px 0px;
  }

  .formLabel {
    font-weight: 500;
    font-size: 13px;
    color: var(--color-gray_3);
  }

  label {
    white-space: nowrap;
  }

  .formTextbox {
    display: flex;
    padding: 12px 0px 12px 12px;
    font-weight: 500;
    height: 40px;
    width: 100%;
    background: var(--color-white);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    color: var(--color-light-06);
  }

  .formSelect {
    height: 40px;
    width: 100%;
    color: var(--color-light-06);
    font-weight: 500;
    font-size: 14px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding-top: 8px;
    padding-left: 12px;
    background: url(../common/assets/svg/down.svg) no-repeat right var(--color-white);
    background-position: right 12px top 8px;
    background-size: 20px;
  }

  .formTextarea {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-light-06);
    height: 117px;
    width: 100%;
    background: var(--color-white);
    border: 1px solid var(--color-light-04);
    border-radius: 6px;
    padding: 12px 0px 12px 12px;
  }
}

.app-form {
  margin-bottom: 32px;
}

.customPaddingButton {
  padding-bottom: 31px !important;
}

.customPaddingTop {
  padding-top: 22px !important;
}

.noPaddingBottom {
  padding-bottom: 0px;
}

.formLabel {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-gray_3);
  margin-bottom: 4px;
}

.selectWithSearch {
  display: flex !important;
  height: 40px;
  background: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.selectWithSearchError {
  display: flex !important;
  height: 40px;
  background: var(--color-white);
  border: 1px solid var(--color-orange);
  border-radius: 6px;
}

.submitButton {
  width: inherit;
  height: inherit !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  border-radius: 6px !important;
  color: var(--color-secondary-100) !important;
  background-color: var(--color-primary-100) !important;
  text-align: center;

  &:disabled {
    background-color: var(--color-primary-20) !important;
    color: var(--color-primary-40) !important;
    border: 1px solid transparent !important;
  }

  &:hover {
    border-color: transparent !important;
  }
}

input[type='number'] {
  border: 1px solid var(--color-light-04);
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  padding: 0 12px 0 12px;
  background-color: var(--color-white);
}
