@mixin device($breakpoint) {
  //the name of the mixin is devices

  @if $breakpoint == microSmall {
    @media only screen and (max-width: 390px) {
      @content;
    }
  }

  @if $breakpoint == extraSmall {
    @media only screen and (max-width: 500px) {
      @content;
    }
  }

  @if $breakpoint == extraMediumSmall {
    @media only screen and (max-width: 580px) {
      @content;
    }
  }

  @if $breakpoint == smallOnly {
    @media only screen and (max-width: 779px) {
      @content;
    }
  }

  @if $breakpoint == smallUp {
    @media only screen and (min-width: 750px) {
      @content;
    }
  }

  @if $breakpoint == mediumOnly {
    @media only screen and (min-width: 780px) and (max-width: 1199px) {
      @content;
    }
  }

  @if $breakpoint == mediumHeightOnly {
    @media only screen and (max-height: 780px) {
      @content;
    }
  }

  @if $breakpoint == extraSmallUp {
    @media only screen and (min-width: 442px) {
      @content;
    }
  }

  @if $breakpoint == mediumSmallUpOnly {
    @media only screen and (min-width: 590px) and (max-width: 1000px) {
      @content;
    }
  }

  @if $breakpoint == upToDesktop {
    @media only screen and (min-width: 780px) and (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint == mediumUp {
    @media only screen and (min-width: 780px) {
      @content;
    }
  }

  @if $breakpoint == largeUp {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint == largeUpPlus {
    @media only screen and (min-width: 1240px) {
      @content;
    }
  }

  @if $breakpoint == extraLargeOnly {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }

  @if $breakpoint == extraLargeUp {
    @media only screen and (min-width: 1300px) {
      @content;
    }
  }

  @if $breakpoint == siteNavigationBar {
    @media only screen and (max-width: 1010px) {
      @content;
    }
  }
  @if $breakpoint == siteFilterButton {
    @media only screen and (max-width: 1240px) {
      @content;
    }
  }
}

$maxWidth: 1920;
$maxWidthContainer: 1170;

@mixin adaptive-value($property, $startSize, $minSize, $type, $important: false) {
  $addSize: $startSize - $minSize;
  @if $type==1 {
    @media (max-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidthContainer - 320}))
        if-important($important);
    }
  } @else if $type==2 {
    @media (min-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}))
        if-important($important);
    }
  } @else {
    #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}))
      if-important($important);
  }
}
