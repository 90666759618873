@keyframes overlayClose {
  0% {
    right: 0;
    position: fixed;
  }
  100% {
    right: -60%;
    position: fixed;
  }
}

@keyframes overlayOpen {
  0% {
    right: -60%;
    position: fixed;
  }
  100% {
    right: 0;
    position: fixed;
  }
}
