@import 'responsive';

body {
  font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  line-height: 1.5;
  background-color: var(--color-white);
}

.relative {
  position: relative;
}

.application-layout {
  display: flex;
  flex-direction: row;

  &__content {
    display: flex;
    width: calc(100% - 100px);
    flex-direction: column;
    margin-left: 100px !important;
    background-color: var(--color-background);
    min-height: 100vh !important;
    padding-bottom: 32px;

    &[data-guest='true'] {
      margin: 0 !important;
      width: 100%;
    }

    @include device(extraMediumSmall) {
      width: 100vw;
      margin: 0 0 0 0 !important;
    }

    &-container {
      padding: 16px 32px;
      flex: 1;

      @include device(extraMediumSmall) {
        padding: 16px;
      }
    }
  }
}

.closeAnimation {
  animation-name: overlayClose;
  animation-duration: 1s;
}

.errorMessage {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  height: 16px;
  margin-bottom: 0;
  color: var(--color-tertiary-color-100);
}

.inputMargin {
  margin-top: 24px;
}

.w-100 {
  width: 100%;
}

.center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.centerStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.red-bordered {
  border: 1px solid var(--color-required) !important;
}
