@import 'src/styles/responsive';

.whole-page-wrapper {
  height: calc(100vh - 100px);
  width: calc(100vw - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  @include device(extraMediumSmall) {
    height: 100vh;
    width: 100%;
  }
}
