.ant-checkbox {
  width: 24px;
  height: 24px;
  top: 0 !important;
}
.ant-checkbox-inner {
  width: inherit !important;
  height: inherit !important;
  border-radius: 4px !important;
  border: 1px solid #cbc2c0 !important;
}

.ant-checkbox-inner::after {
  left: 25% !important;
  top: 45% !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: transparent !important;
  border: 1px solid #cbc2c0 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  border: transparent !important;
  background-color: var(--color-primary-100) !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  width: 9px;
  height: 17px;
  border: 2px solid var(--color-secondary-100) !important;
  border-top: 0 !important;
  border-left: 0 !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.slick-slider {
  position: relative !important;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}

.ant-carousel .slick-next,
.ant-carousel .slick-prev {
  position: absolute !important;
  width: 24px !important;
  height: 24px !important;
  top: 107px !important;
  z-index: 1;
}

.ant-carousel .slick-prev {
  left: 15px !important;
}

.ant-carousel .slick-next {
  right: 15px !important;
}

.ant-table-cell input {
  display: flex;
  padding: 12px 0px 12px 12px;
  font-weight: 500;
  font-size: 12px;
  height: 30px;
  width: 100%;
  background: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  color: var(--color-light-06);
}

.actionsList {
  max-width: 65px;
  height: 23px;
}

.actionsList .ant-select-selection-item {
  color: var(--color-white) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--color-primary-100) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer .ant-select-selector {
  background-color: var(--color-white) !important;
  color: var(--color-tertiary-color-100) !important;
  padding: 4px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid var(--color-background-select-arrow) !important;
}

.table-select {
  .ant-select-single,
  .ant-select-selector {
    cursor: pointer !important;
    height: 23px !important;
  }
}

.ant-pagination-options .ant-select {
  .ant-select-selector {
    cursor: pointer !important;
    height: 25px !important;
  }
}

.ant-pagination-options .ant-select-selection-search input {
  display: none !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer .ant-select-selection-item {
  padding-right: 0px !important;
  line-height: 15px !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer .ant-select-arrow {
  width: 20px !important;
  height: 25px !important;
  background-color: var(--color-background-select-arrow) !important;
  background: url(../common/assets/svg/down.svg) no-repeat right var(--color-white);
  background-size: 75%;
  border-radius: 0px 6px 6px 0px;
  right: 0px;
  top: 6px;
  background-position: center;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer .ant-select-arrow .anticon svg {
  fill: transparent;
}

.actionsList .ant-select-selector {
  background-color: var(--color-orange1) !important;
  color: var(--color-white) !important;
  padding: 4px !important;
  font-size: 12px !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.ant-select.actionsList .ant-select-selection-search-input {
  height: 23px !important;
}

.actionsList .ant-select-selector .ant-select-selection-placeholder {
  color: var(--color-white) !important;
  font-size: 12px !important;
  padding: 0 !important;
}

.actionsList .ant-select-arrow {
  width: 20px !important;
  height: 23px !important;
  background-color: var(--color-background-select-arrow) !important;
  background: url(../common/assets/svg/down.svg) no-repeat right var(--color-white);
  background-size: 75%;
  border-radius: 0px 6px 6px 0px;
  right: 0px;
  top: 6px;
  background-position: center;
}

.actionsList .ant-select-arrow .anticon svg {
  fill: transparent;
}

.ant-select-selection-item {
  line-height: 40px !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: transparent !important;
}

.ant-table-wrapper {
  z-index: 1 !important;
  position: relative !important;
}

.ant-table-body {
  overflow-x: auto;
}

.ant-table-scroll .ant-table-body {
  overflow-y: scroll;
}

.ant-table-container .ant-table-header > tr,
.ant-table-container .ant-table-body > tr {
  height: 48px !important;
}

.ant-table-container .ant-table-thead > tr > th,
.ant-table-container .ant-table-body .ant-table-cell {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  color: var(--color-dark-04);
  vertical-align: middle;
}

.ant-table-sticky-holder {
  top: 64px !important;
}

.ant-alert-error {
  padding: 16px !important;
  position: fixed !important;
  width: 552px;
  height: 91px;
  bottom: 44px;
  border: 1px solid var(--color-dark-04) !important;
  box-shadow: 0px 4px 4px rgb(82 79 78 / 24%);
  border-radius: 13px !important;
  z-index: 10000;
}

.successfull {
  background-color: var(--color-green) !important;
}

.warning {
  background-color: var(--color-primary-100) !important;
}

.error {
  background-color: var(--color-orange) !important;
}

.customInfoMessage p {
  color: var(--color-white);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}

.customInfoMessage p img {
  margin-right: 8px;
  margin-top: -2px;
}

.customInfoMessage .ant-alert-close-icon span svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 24px;
  right: 24px;
}

.antdFormSelect {
  height: 40px;
  width: 100%;
  color: var(--color-light-06);
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  padding-top: 8px;
  padding-left: 12px;
  background: url(../common/assets/svg/down.svg) no-repeat right var(--color-white);
  background-position: right 12px top 8px;
  background-size: 20px;
}

.antdFormSelect .ant-select-selector {
  height: 40px !important;
  border-radius: 6px !important;
}

.ant-spin-nested-loading {
  z-index: 0 !important;
}

.ant-select {
  display: inline-flex !important;
}

.ant-select .ant-select-selector {
  /* height: inherit !important; */
  border: none !important;
}

.ant-select .ant-select-selection-search-input {
  height: 40px !important;
}

.controlContainer .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-top: 10px !important;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-light-06);
}

.ant-checkbox-wrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-checkbox-wrapper-disabled {
  opacity: 0.5;
}

.ant-input-number {
  width: 100%;
  height: 40px;
  border: none;
}

.ant-input-password {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  box-shadow: none;
}

.ant-input-affix-wrapper {
  border: none !important;
  height: 100%;
}

.ant-input-affix-wrapper:focus {
  box-shadow: none !important;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-input[type='date'] {
  padding: 6px 12px 0 40px;
}

.ant-picker-time-panel-column::after {
  display: none !important;
}

.ant-picker-input {
  display: flex;
  column-gap: 8px;
  flex-direction: row-reverse;
}

.ant-picker-input input::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-light-06) !important;
}

.ant-picker {
  font-weight: 500;
  height: 38px !important;
  border-radius: 6px !important;
  border: 1px solid var(--color-light-04) !important;
}

.ant-picker:hover,
.ant-picker:focus,
.ant-picker-focused {
  .ant-picker-suffix {
    opacity: 1;
  }
  border: 1px solid var(--color-light-04) !important;
}

.ant-picker-input > input[disabled] {
  color: var(--color-gray_2);
}

.ant-picker-status-error.ant-picker {
  border: 1px solid var(--color-required) !important;
}

.ant-select-selector .ant-select-selection-placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-light-06);
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-table-thead {
  height: 48px;
}

.ant-table table .ant-table-row {
  height: 40px !important;
}

.ant-table.ant-table-small {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ant-table .ant-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
}

.ant-pagination.ant-pagination-mini {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ant-table-pagination.ant-pagination {
  display: flex !important;
  align-items: center !important;
}

.ant-pagination-item:hover a {
  color: var(--color-tertiary-color-100) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-next a {
  color: var(--color-tertiary-color-100) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev a {
  color: var(--color-tertiary-color-100) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-next a[disabled] {
  color: var(--color-light-06) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev a[disabled] {
  color: var(--color-light-06) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev {
  margin-right: 26px !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-next {
  margin-left: 26px !important;
}

@media only screen and (max-width: 576px) {
  .ant-table-pagination.ant-pagination {
    justify-content: end !important;
  }

  .ant-pagination-options {
    display: flex !important;
  }
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  margin-left: 30px !important;
  height: 25px;
  width: 90px !important;
}

.ant-pagination-item {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
  border: 1px solid var(--color-dark-04) !important;
  margin: 0 4px !important;
  height: 40px !important;
  width: 40px !important;
  line-height: 40px !important;
  font-weight: 400 !important;
}

.ant-pagination-item a {
  color: var(--color-dark-04) !important;
}

.ant-pagination-item-active {
  border: none !important;
  background-color: var(--color-primary-100) !important;
}

.ant-pagination-item-active a {
  color: var(--color-dark-04) !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  font-weight: 500 !important;
}

.ant-input,
.controlContainer textarea,
.controlContainer .ant-select-selection-item {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: var(--color-gray_2) !important;
}

/* Tooltip */
.ant-tooltip-inner {
  color: var(--color-dark-05) !important;
  background-color: var(--color-white) !important;
  width: auto !important;
  border-radius: 8px !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px 8px 12px !important;
  z-index: 1000;
}
.ant-tooltip-arrow {
  --antd-arrow-background-color: var(--color-white) !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  text-align: left;
}

.lineHeightSelect .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  line-height: 40px !important;
}

/* TabBar */

.ant-tabs-ink-bar {
  background: transparent !important;
}

.ant-tabs-ink-bar::after {
  content: '';
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  background: var(--color-tertiary-color-100) !important;
  border-radius: 4px;
  height: 3px !important;
  width: 26px;
  transform: translateX(-50%);
}

.ant-tabs-nav::before {
  border: none !important;
}

.ant-tabs-tab-btn {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--color-dark-01) !important;
  transition: none !important;
}

.ant-tabs-tab-btn:hover {
  color: var(--color-tertiary-color-100) !important;
}
.ant-tabs-tab-active {
  transition: none !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: var(--color-dark-05) !important;
}

.ant-tabs-tab {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--color-dark-01) !important;
  user-select: none !important;
  transition: none !important;
}

.ant-input-group-addon {
  border: none !important;
  /* background-color: var( --color-disabled) !important; */
}

.inputTest {
  width: inherit;
  height: 40px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--color-light-04);
  background-color: var(--color-light-01);
  width: 100%;
}

.ant-select-dropdown {
  padding: 0 !important;
  box-shadow: 0 4px 4px rgba(78, 78, 90, 0.16);
  border-radius: 4px !important;
  min-width: max-content !important;
}

/* .ant-popover-arrow-content {
  display: none;
} */

/*.table-select-content {*/
/*  min-width: min-content !important;*/
/*}*/

/*.table-select-content .ant-select-item {*/
/*  min-height: min-content;*/
/*  padding: 6px 8px 6px 8px !important;*/
/*}*/

/*.table-select-content .ant-select-item-option-content {*/
/*  font-size: 12px !important;*/
/*  line-height: 15px !important;*/
/*  font-weight: 500;*/
/*  color: var(--color-dark-04);*/
/*}*/

.ant-select-dropdown .ant-select-item-option-active {
  background-color: var(--color-light-03) !important;
}

.dropdownContent .ant-select-item-option-content {
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-dark-04);
}

.dropdownContent {
  min-width: min-content !important;
}

.dropdownContent .ant-select-item {
  border-radius: 4px;
  min-height: min-content;
  padding: 6px 8px 6px 8px !important;
}

.table-select-content {
  color: rebeccapurple;
}

.ant-popover-title {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  border-bottom: none !important;
  padding-top: 24px !important;
}

.ant-spin-dot-item {
  background-color: var(--color-primary-100) !important;
}

.searchBox.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  left: 40px !important;
  top: 2px !important;
}

.searchBox.ant-select {
  cursor: text !important;
  width: 100% !important;
}

.searchBox.ant-select .ant-select-selector {
  width: 100% !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  z-index: auto !important;
}

/* .ant-tabs-content.ant-tabs-content-top .ant-tabs-tabpane-active{
  display: none;
} */

td:first-child,
td:last-child {
  z-index: 1;
  background-color: var(--color-white);
}

.ant-btn:active,
.ant-btn:focus {
  border: 1px solid var(--border-color1) !important;
}

.ant-input:focus {
  box-shadow: none;
}
